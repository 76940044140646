body,
html {
	position: relative;
	height: 100%;
	min-height: 100%;
	margin: 0;
}

body {
	font-family: 'Open Sans', Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Deezer', Arial, sans-serif;
}

// add a bg gradient for avatar group
.members > .chakra-avatar__excess {
	background: linear-gradient(45deg, #1d2caf 0%, #a668f5 100%);
}
